import React, { useState } from 'react';
import * as Form from '@radix-ui/react-form';
import * as Label from '@radix-ui/react-label';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';

const LoginPage = () => {
    const [serverErrors, setServerErrors] = useState(window.serverErrors || []);

    return (
        <div className="min-h-screen flex items-center justify-center bg-background dark:bg-background">
            <div className="w-full max-w-md bg-white dark:bg-secondary rounded-lg shadow-lg p-8">
                {/* Header */}
                <div className="text-center mb-8">
                    <h1 className="text-display-sm font-bold text-foreground mb-2">Welcome back</h1>
                    <p className="text-muted-foreground">Please sign in to your account</p>
                </div>

                {/* Error Messages */}
                {serverErrors.length > 0 && (
                    <div className="mb-6 p-4 bg-destructive/10 border border-destructive/20 rounded-md">
                        {serverErrors.map((error, index) => (
                            <p key={index} className="text-sm text-destructive">{error}</p>
                        ))}
                    </div>
                )}

                {/* Login Form */}
                <Form.Root
                    className="space-y-6"
                    method="POST"
                    action="/login"
                >
                    <input
                        type="hidden"
                        name="_token"
                        value={document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')}
                    />

                    {/* Email Field */}
                    <Form.Field name="email">
                        <div className="space-y-2">
                            <Form.Label className="block text-sm font-medium text-foreground">
                                Email address
                            </Form.Label>
                            <Form.Control asChild>
                                <input
                                    type="email"
                                    placeholder="name@example.com"
                                    className="block w-full rounded-md border border-input bg-background px-3 py-2 text-foreground shadow-sm placeholder:text-muted-foreground focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                                    required
                                />
                            </Form.Control>
                            <Form.Message
                                match="valueMissing"
                                className="text-sm text-destructive"
                            >
                                Please enter your email
                            </Form.Message>
                            <Form.Message
                                match="typeMismatch"
                                className="text-sm text-destructive"
                            >
                                Please provide a valid email
                            </Form.Message>
                        </div>
                    </Form.Field>

                    {/* Password Field */}
                    <Form.Field name="password">
                        <div className="space-y-2">
                            <Form.Label className="block text-sm font-medium text-foreground">
                                Password
                            </Form.Label>
                            <Form.Control asChild>
                                <input
                                    type="password"
                                    className="block w-full rounded-md border border-input bg-background px-3 py-2 text-foreground shadow-sm placeholder:text-muted-foreground focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                                    required
                                />
                            </Form.Control>
                            <Form.Message
                                match="valueMissing"
                                className="text-sm text-destructive"
                            >
                                Please enter your password
                            </Form.Message>
                        </div>
                    </Form.Field>

                    {/* Remember Me & Forgot Password */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <Checkbox.Root
                                className="flex h-4 w-4 items-center justify-center rounded border border-input bg-background focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
                                id="remember"
                                name="remember"
                            >
                                <Checkbox.Indicator>
                                    <Check className="h-3 w-3" />
                                </Checkbox.Indicator>
                            </Checkbox.Root>
                            <Label.Root
                                className="text-sm text-muted-foreground"
                                htmlFor="remember"
                            >
                                Remember me
                            </Label.Root>
                        </div>

                        <a
                            href="/password/reset"
                            className="text-sm text-primary hover:text-primary/90 hover:underline focus:outline-none focus-visible:ring-2 focus-visible:ring-primary"
                        >
                            Forgot your password?
                        </a>
                    </div>

                    {/* Submit Button */}
                    <Form.Submit asChild>
                        <button
                            type="submit"
                            className="w-full rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                        >
                            Sign in
                        </button>
                    </Form.Submit>
                </Form.Root>
            </div>
        </div>
    );
};

export default LoginPage;
