import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/ui/table';
import { format, isValid, parseISO } from 'date-fns';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import GoogleNewsCountry from './GoogleNewsCountry';
import RequestEntries from './RequestEntries';
import SourceStats from './SourceStats';

const COLORS = ['#3b82f6', '#10b981', '#6366f1', '#f59e0b', '#ef4444'];
const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const NewsScrappingDashboard = () => {
    // Fixed initial state to match API response structure
    const [stats, setStats] = useState({
        countryStats: [],
        crawlerStats: [],
        performanceStats: {
            data: []
        }
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const [countryData, crawlerData, performanceData] = await Promise.all([
                    fetch('/api/dashboard/news/country-stats').then(r => r.json()),
                    fetch('/api/dashboard/news/crawler-stats').then(r => r.json()),
                    fetch('/api/dashboard/news/performance').then(r => r.json())
                ]);

                console.log('API Responses:', {
                    countryData,
                    crawlerData,
                    performanceData
                });

                setStats({
                    countryStats: countryData || [],
                    crawlerStats: crawlerData || [],
                    performanceStats: performanceData || { data: [] }
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching stats:', error);
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    const formatBytes = (bytes) => {
        if (!bytes) return '0 B';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    const formatDuration = (ms) => {
        if (!ms) return '0ms';
        if (ms < 1000) return `${ms}ms`;
        return `${(ms/1000).toFixed(2)}s`;
    };

    const formatDate = (dateStr) => {
        try {
            const date = parseISO(dateStr);
            return isValid(date) ? format(date, 'yyyy-MM-dd HH:mm:ss') : 'Invalid Date';
        } catch {
            return 'Invalid Date';
        }
    };

    if (loading) {
        return <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {[...Array(6)].map((_, i) => (
                <Card key={i} className="animate-pulse">
                    <CardHeader>
                        <div className="h-4 bg-muted rounded w-2/3" />
                    </CardHeader>
                    <CardContent>
                        <div className="h-48 bg-muted rounded" />
                    </CardContent>
                </Card>
            ))}
        </div>;
    }

    // Transform data for Nivo bar chart
    const barData = stats.countryStats.map(stat => ({
        country: stat.country || 'Unknown',
        'Total Requests': stat.total_requests || 0,
        'Successful Requests': parseInt(stat.successful_requests) || 0,
    }));

    // Transform data for Nivo pie chart
    const pieData = stats.crawlerStats.map(stat => ({
        id: stat.crawler_type || 'Unknown',
        label: stat.crawler_type || 'Unknown',
        value: stat.total_requests || 0,
    }));

    return (
        <div className="space-y-6">
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                {/* Country Statistics */}
                <Card className="w-calc-mobile md:w-auto">
                    <CardHeader>
                        <CardTitle>Requests by Country</CardTitle>
                    </CardHeader>
                    <CardContent className="h-[300px]">
                        {barData.length > 0 ? (
                            <ResponsiveBar
                                data={barData}
                                keys={['Total Requests', 'Successful Requests']}
                                indexBy="country"
                                margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                colors={['#3b82f6', '#10b981']}
                                animate={true}
                                enableLabel={false}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: -45,
                                }}
                            />
                        ) : (
                            <div className="h-full flex items-center justify-center text-muted-foreground">
                                No country data available
                            </div>
                        )}
                    </CardContent>
                </Card>

                {/* Crawler Type Distribution */}
                <Card className="w-calc-mobile md:w-auto">
                    <CardHeader>
                        <CardTitle>Requests by Type</CardTitle>
                    </CardHeader>
                    <CardContent className="h-[300px] max-w-[100%]">
                        {stats.crawlerStats.length > 0 ? (
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={stats.crawlerStats}
                                        dataKey="total_requests"
                                        nameKey="request_type"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius="50%"
                                        outerRadius="80%"
                                        label={({name, percent}) => `${name} (${(percent * 100).toFixed(0)}%)`}
                                    >
                                        {stats.crawlerStats.map((entry, index) => (
                                            <Cell key={index} fill={colors[index % colors.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        ) : (
                            <div className="h-full flex items-center justify-center text-muted-foreground">
                                No request type data available
                            </div>
                        )}
                    </CardContent>
                </Card>

                {/* Proxy Service Usage */}
                <Card className="w-calc-mobile md:w-auto">
                    <CardHeader>
                        <CardTitle>Proxy Service Usage</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Service</TableHead>
                                    <TableHead>Requests</TableHead>
                                    <TableHead>Success Rate</TableHead>
                                    <TableHead>Avg Response Time</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {stats?.crawlerStats?.length > 0 ? (
                                    stats.crawlerStats.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.proxy_service}</TableCell>
                                            <TableCell>{item.total_requests}</TableCell>
                                            <TableCell>
                                                {((parseInt(item.successful_requests) / item.total_requests) * 100).toFixed(1)}%
                                            </TableCell>
                                            <TableCell>
                                                {parseFloat(item.avg_response_time).toFixed(0)}ms
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-center text-muted-foreground">
                                            No proxy service data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
            <div className="mt-4">
                {/* Performance Stats */}
                <Card>
                    <CardHeader>
                        <CardTitle>Slowest Responses</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>URL</TableHead>
                                    <TableHead>Response Time</TableHead>
                                    <TableHead>Status</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {stats?.performanceStats?.data ? (
                                    [...stats.performanceStats.data]
                                        .sort((a, b) => b.response_time_ms - a.response_time_ms)
                                        .slice(0, 5)
                                        .map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className="max-w-[200px] truncate">
                                                    {item.url}
                                                </TableCell>
                                                <TableCell>
                                                    {formatDuration(item.response_time_ms)}
                                                </TableCell>
                                                <TableCell>
                                                    <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium
                                                        ${item.status_code >= 200 && item.status_code < 300
                                                            ? 'bg-success/10 text-success border border-success/20'
                                                            : 'bg-destructive/10 text-destructive border border-destructive/20'
                                                        }`}>
                                                        {item.status_code}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} className="text-center text-muted-foreground">
                                            No performance data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
            <div className="mt-4">
                <GoogleNewsCountry />
            </div>
            <div className="mt-4">
                <RequestEntries />
            </div>
            <div className="mt-4">
                <SourceStats />
            </div>
        </div>
    );
};

export default NewsScrappingDashboard;
