console.log('Starting app.jsx');

import './bootstrap';
import React from 'react';
import { createRoot } from 'react-dom/client';

// Import components
console.log('Importing components...');
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/auth/LoginPage';

console.log('Components imported');

// Explicitly separate the mounting logic
const mountLogin = () => {
    console.log('Attempting to mount Login');
    const loginContainer = document.getElementById('login-app');
    if (loginContainer) {
        console.log('Found login container');
        const root = createRoot(loginContainer);
        root.render(
            <React.StrictMode>
                <LoginPage />
            </React.StrictMode>
        );
        console.log('Login mounted');
    }
};

const mountDashboard = () => {
    console.log('Attempting to mount Dashboard');
    const dashboardContainer = document.getElementById('dashboard-app');
    if (dashboardContainer) {
        console.log('Found dashboard container');
        try {
            const root = createRoot(dashboardContainer);
            root.render(
                <React.StrictMode>
                    <Dashboard />
                </React.StrictMode>
            );
            console.log('Dashboard mounted');
        } catch (error) {
            console.error('Failed to mount Dashboard:', error);
            dashboardContainer.innerHTML = `<div class="p-4 bg-red-100 text-red-700">Error mounting dashboard: ${error.message}</div>`;
        }
    } else {
        console.log('Dashboard container not found');
    }
};

// Execute mounts
console.log('Starting mount process');
mountLogin();
mountDashboard();
console.log('Finished mount attempts');
