import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { format } from 'date-fns';

const GoogleNewsCountry = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/dashboard/news/google-news-country');
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
        const interval = setInterval(fetchData, 60000); // Refresh every minute
        return () => clearInterval(interval);
    }, []);

    return (
        <Card>
            <CardHeader>
                <CardTitle>Google News Requests by Country</CardTitle>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Country</TableHead>
                            <TableHead>Total Requests</TableHead>
                            <TableHead>Success Rate</TableHead>
                            <TableHead>Last Request</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.source_country}>
                                <TableCell>{row.source_country}</TableCell>
                                <TableCell>{row.request_count}</TableCell>
                                <TableCell>
                                    {((row.successful_requests / row.request_count) * 100).toFixed(2)}%
                                </TableCell>
                                <TableCell>
                                    {format(new Date(row.last_request_time), 'yyyy-MM-dd HH:mm:ss')}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default GoogleNewsCountry;
