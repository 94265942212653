import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { LogOut, ChevronDown } from 'lucide-react';

const Header = () => {
  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur">
      <div className="container flex h-16 items-center px-4">
        {/* Logo */}
        <div className="flex items-center gap-2">
          {/* <img
            src="/api/placeholder/32/32"
            alt="Logo"
            className="h-8 w-8"
          /> */}
          <span className="text-lg font-semibold">Monitor</span>
        </div>

        <div className="ml-auto flex items-center gap-4">
          {/* User Menu */}
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <button className="flex items-center gap-2 rounded-full p-1 hover:bg-muted">
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                    <span className="text-sm font-medium text-primary">
                      {window.user?.name?.[0] ?? 'U'}
                    </span>
                  </div>
                  <span className="text-sm font-medium hidden md:inline-block">
                    {window.user?.name ?? 'User'}
                  </span>
                </div>
                <ChevronDown className="h-4 w-4" />
              </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="z-50 min-w-[8rem] overflow-hidden rounded-md border bg-background p-1 shadow-md"
                align="end"
              >
                <form action="/logout" method="POST">
                  <input
                    type="hidden"
                    name="_token"
                    value={document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')}
                  />
                  <DropdownMenu.Item className="relative flex w-full cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-muted">
                    <button type="submit" className="flex items-center gap-2 w-full">
                      <LogOut className="h-4 w-4" />
                      <span>Logout</span>
                    </button>
                  </DropdownMenu.Item>
                </form>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </div>
    </header>
  );
};

export default Header;
