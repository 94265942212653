import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CrawlerStatus from '@/components/dashboard/NewsScrapping/CrawlerStatus';

const ResourceAnalytics = () => {

    const [resourceMetrics, setResourceMetrics] = useState({
        memoryUsage: [],
        apiPerformance: [],
        costEfficiency: [],
        resourceData: [], // Added this
        loading: true
    });

    const calculateEfficiencyScore = (data) => {
        if (!data) return {};
        return {
            ...data,
            efficiencyScore: (parseFloat(data.successful_requests) / data.total_requests * 100) *
                            (1 - data.memory_usage_mb / 1000) *
                            (1 - parseFloat(data.cpu_usage_percent) / 100)
        };
    };

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const [resourceStats, apiStats] = await Promise.all([
                    fetch('/api/analytics/resource-stats'),
                    fetch('/api/analytics/api-performance')
                ]);

                const resourceData = await resourceStats.json();
                const apiData = await apiStats.json();

                const processedData = Array.isArray(resourceData) ?
                    resourceData.map(calculateEfficiencyScore) : [];

                setResourceMetrics({
                    memoryUsage: processedData,
                    apiPerformance: apiData,
                    resourceData: resourceData, // Add raw resourceData
                    costEfficiency: apiData.map(d => ({
                        country: d.source_country,
                        efficiency: d.total_requests > 0 ? (parseFloat(d.successful_requests) / d.total_requests * 100).toFixed(2) : 0,
                        cost: parseFloat(d.total_credits),
                        success_rate: (parseFloat(d.successful_requests) / d.total_requests * 100)
                    })),
                    loading: false
                });
            } catch (error) {
                console.error('Error fetching metrics:', error);
                setResourceMetrics(prev => ({...prev, loading: false}));
            }
        };

        fetchMetrics();
        const interval = setInterval(fetchMetrics, 60000);
        return () => clearInterval(interval);
    }, []);

    const getRecommendations = (metrics) => {
        if (!metrics.length) return [];

        const avgMemory = metrics.reduce((acc, curr) => acc + parseFloat(curr.memory_usage_mb || 0), 0) / metrics.length;
        const avgCpu = metrics.reduce((acc, curr) => acc + parseFloat(curr.cpu_usage_percent || 0), 0) / metrics.length;

        return metrics
            .filter(metric => metric.memory_usage_mb && metric.cpu_usage_percent)
            .reduce((acc, metric) => {
                if (metric.memory_usage_mb > avgMemory * 1.2) {
                    acc.push(`High memory usage in ${metric.source_country}: ${metric.memory_usage_mb}MB`);
                }
                if (parseFloat(metric.cpu_usage_percent) > avgCpu * 1.2) {
                    acc.push(`High CPU usage in ${metric.source_country}: ${metric.cpu_usage_percent}%`);
                }
                return acc;
            }, []);
    };

    if (resourceMetrics.loading) {
        return <div className="animate-pulse">Loading analytics...</div>;
    }

    const recommendations = getRecommendations(resourceMetrics.memoryUsage);

    const formatBandwidth = (bytes) => {
        if (bytes < 1024) return `${bytes} B`;
        if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
        if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
        return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    };

    // Update Card tooltips
    const tooltips = {
        memory: "RAM usage allocated for crawling tasks",
        cpu: "Percentage of CPU resources utilized during crawling",
        bandwidth: "Total data transferred during crawling operations"
    };

    return (
        <div className="space-y-6">

            {/* Recommendations */}
            <Card className="w-full">
                <CardHeader>
                    <CardTitle>Optimization Recommendations</CardTitle>
                </CardHeader>
                <CardContent>
                    <ul className="space-y-2">
                        {recommendations.map((rec, index) => (
                            <li key={index} className="flex items-center text-sm text-muted-foreground">
                                <span className="w-2 h-2 mr-2 rounded-full bg-yellow-500"></span>
                                {rec}
                            </li>
                        ))}
                    </ul>
                </CardContent>
            </Card>


            <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                <CrawlerStatus />
                <Card className="w-full overflow-hidden">
                    <CardHeader>
                        <CardTitle>Country Resource Usage</CardTitle>
                    </CardHeader>
                    <CardContent className="h-[400px] overflow-x-auto">
                    <div className="min-w-[600px] h-full">
                        <ResponsiveContainer>
                                <BarChart data={resourceMetrics.resourceData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="source_country" />
                                    <YAxis yAxisId="left" orientation="left" />
                                    <YAxis yAxisId="right" orientation="right" />
                                    <Tooltip
                                        formatter={(value, name) => {
                                            if (name === "Bandwidth") return formatBandwidth(value);
                                            if (name === "Memory (MB)") return `${value} MB`;
                                            if (name === "CPU (%)") return `${value}%`;
                                            return value;
                                        }}
                                    />
                                    <Legend />
                                    <Bar yAxisId="left" dataKey="memory_usage_mb" name="Memory (MB)" fill="#3b82f6" />
                                    <Bar yAxisId="right" dataKey="cpu_usage_percent" name="CPU (%)" fill="#10b981" />
                                    <Bar yAxisId="left" dataKey="bandwidth_used_bytes" name="Bandwidth" unit="bytes" fill="#f43f5e" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div className="grid gap-4 md:grid-cols-2">
                {/* Resource Usage Chart */}
                <Card className="w-full">
                    <CardHeader>
                        <CardTitle>Resource Usage by Country</CardTitle>
                    </CardHeader>
                    <CardContent className="h-[400px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={resourceMetrics.memoryUsage}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="source_country" />
                                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                                <Tooltip />
                                <Legend />
                                <Bar yAxisId="left" dataKey="memory_usage_mb" name="Memory (MB)" fill="#8884d8" />
                                <Bar yAxisId="right" dataKey="cpu_usage_percent" name="CPU (%)" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>

                {/* Cost Efficiency Chart */}
                <Card className="w-full">
                    <CardHeader>
                        <CardTitle>Cost Efficiency Analysis</CardTitle>
                    </CardHeader>
                    <CardContent className="h-[400px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={resourceMetrics.costEfficiency}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="country" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="efficiency" name="Efficiency Score" stroke="#8884d8" />
                                <Line type="monotone" dataKey="success_rate" name="Success Rate" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </div>

        </div>
    );
};

export default ResourceAnalytics;
