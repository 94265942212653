import React from 'react';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import NewsScrappingDashboard from '../components/dashboard/NewsScrapping';
import MetricsDashboard from '../components/dashboard/MetricsDashboard';
import ResourceAnalytics from '../components/analytics/ResourceAnalytics';

const Dashboard = () => {
    return (
        <DashboardLayout>
            <div className="space-y-8">
                <div className="">
                    <h1 className="text-2xl font-bold">Dashboard Summary</h1>
                    {/* <p className="text-gray-600">Welcome back, {window.user?.name}</p> */}
                </div>

                <div className="space-y-4">
                    <h2 className="text-xl font-semibold">Resource Analytics</h2>
                    <ResourceAnalytics />
                </div>

                {/* Success Rate and Response Time Metrics */}
                <div className="space-y-4">
                    <h2 className="text-xl font-semibold">Performance Metrics</h2>
                    <MetricsDashboard />
                </div>

                {/* News scrapping statistics section */}
                <div className="space-y-4">
                    <h2 className="text-xl font-semibold">News Scrapping Statistics</h2>
                    <NewsScrappingDashboard />
                </div>
            </div>
        </DashboardLayout>
    );
};

export default Dashboard;
