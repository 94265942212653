import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { ArrowUpDown } from 'lucide-react';
import { format } from 'date-fns';
import { Pagination } from "@/components/ui/pagination";

const RequestEntries = () => {
    const [data, setData] = useState({
        data: [],
        current_page: 1,
        last_page: 1,
        total: 0,
        per_page: 10
    });
    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async (page, pageSize = data.per_page) => {
        setIsLoading(true);
        try {
            const response = await fetch(
                `/api/dashboard/news/request-entries?page=${page}&per_page=${pageSize}&sort=${sortConfig.key}&direction=${sortConfig.direction}`
            );
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(data.current_page);
    }, [sortConfig]);

    const handleSort = (key) => {
        setSortConfig(current => ({
            key,
            direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handlePageChange = (page) => {
        fetchData(page);
    };

    const handlePageSizeChange = (newSize) => {
        fetchData(1, newSize);
    };

    const SortableHeader = ({ column, children }) => (
        <TableHead
            className="cursor-pointer hover:bg-muted/50"
            onClick={() => handleSort(column)}
        >
            <div className="flex items-center gap-2">
                {children}
                <ArrowUpDown className="h-4 w-4" />
                {sortConfig.key === column && (
                    <span className="ml-1 text-xs">
                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                    </span>
                )}
            </div>
        </TableHead>
    );

    return (
        <Card>
            <CardHeader>
                <CardTitle>Successful Request Entries</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="relative">
                    {isLoading && (
                        <div className="absolute inset-0 bg-background/50 flex items-center justify-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                        </div>
                    )}
                    <Table>
                        <TableHeader>
                            <TableRow>
                            <SortableHeader column="source_country">Country</SortableHeader>
                            <SortableHeader column="request_type">Request Type</SortableHeader>
                            <SortableHeader column="source_name">Source Name</SortableHeader>
                            <TableHead className="max-w-[200px]">Source URL</TableHead>
                            <SortableHeader column="created_at">Date & Time</SortableHeader>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.source_country}</TableCell>
                                    <TableCell>{row.request_type}</TableCell>
                                    <TableCell>{row.source_name}</TableCell>
                                    <TableCell className="max-w-xs truncate">
                                        <a
                                            href={row.source_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-primary hover:underline"
                                        >
                                            {row.source_url}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        {format(new Date(row.created_at), 'yyyy-MM-dd HH:mm:ss')}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        currentPage={data.current_page}
                        totalPages={data.last_page}
                        totalItems={data.total}
                        pageSize={data.per_page}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </CardContent>
        </Card>
    );
};

export default RequestEntries;
