import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { ArrowUpDown } from 'lucide-react';
import { format } from 'date-fns';
import { Pagination } from "@/components/ui/pagination";

const SourceStats = () => {
    const [data, setData] = useState({ data: [], current_page: 1, last_page: 1, total: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'last_success_time', direction: 'desc' });
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async (page = 1) => {
        setIsLoading(true);
        try {
            const response = await fetch(
                `/api/dashboard/news/source-stats?page=${page}&sort=${sortConfig.key}&direction=${sortConfig.direction}`
            );
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage);
        const interval = setInterval(() => fetchData(currentPage), 60000); // Refresh every minute
        return () => clearInterval(interval);
    }, [currentPage, sortConfig]);

    const handleSort = (key) => {
        setSortConfig(current => ({
            key,
            direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
        }));
        setCurrentPage(1); // Reset to first page when sorting changes
    };

    const SortableHeader = ({ column, children }) => (
        <TableHead
            className="cursor-pointer hover:bg-muted/50"
            onClick={() => handleSort(column)}
        >
            <div className="flex items-center gap-2">
                {children}
                <ArrowUpDown className="h-4 w-4" />
                {sortConfig.key === column && (
                    <span className="ml-1 text-xs">
                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                    </span>
                )}
            </div>
        </TableHead>
    );

    return (
        <Card>
            <CardHeader>
                <CardTitle>Source Statistics</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="relative">
                    {isLoading && (
                        <div className="absolute inset-0 bg-background/50 flex items-center justify-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                        </div>
                    )}
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <SortableHeader column="source_name">Source Name</SortableHeader>
                                <SortableHeader column="google_news_count">Google News Requests</SortableHeader>
                                <SortableHeader column="scrape_count">Scrape Requests</SortableHeader>
                                <SortableHeader column="last_success_time">Last Success</SortableHeader>
                                <SortableHeader column="last_request_type">Last Request Type</SortableHeader>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.source_name}</TableCell>
                                    <TableCell>{row.google_news_count}</TableCell>
                                    <TableCell>{row.scrape_count}</TableCell>
                                    <TableCell>
                                        {row.last_success_time ?
                                            format(new Date(row.last_success_time), 'yyyy-MM-dd HH:mm:ss')
                                            : 'N/A'
                                        }
                                    </TableCell>
                                    <TableCell>{row.last_request_type || 'N/A'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        currentPage={data.current_page}
                        totalPages={data.last_page}
                        onPageChange={setCurrentPage}
                    />
                </div>
            </CardContent>
        </Card>
    );
};

export default SourceStats;
