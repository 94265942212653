import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { format, formatDistanceToNow } from 'date-fns';

const CrawlerStatus = () => {
    const [statusData, setStatusData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/dashboard/news/crawler-status');
                const data = await response.json();
                setStatusData(data);
            } catch (error) {
                console.error('Error fetching crawler status:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
        // Refresh every minute
        const interval = setInterval(fetchData, 60000);
        return () => clearInterval(interval);
    }, []);

    const getStatusIndicator = (minutesAgo) => {
        const isActive = minutesAgo < 120; // 2 hours threshold
        return (
            <div className="flex items-center gap-2">
                <div
                    className={`w-3 h-3 rounded-full ${
                        isActive ? 'bg-green-500' : 'bg-red-500'
                    }`}
                />
                <span className={`text-sm ${
                    isActive ? 'text-green-600' : 'text-red-600'
                }`}>
                    {isActive ? 'Active' : 'Inactive'}
                </span>
            </div>
        );
    };

    const getTimeAgo = (dateTime) => {
        try {
            const date = new Date(dateTime);
            return formatDistanceToNow(date, { addSuffix: true });
        } catch (error) {
            return 'Invalid date';
        }
    };

    if (isLoading) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Crawler Status</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex items-center justify-center p-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                    </div>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Crawler Status</CardTitle>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Country</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Last Request</TableHead>
                            <TableHead>Time Ago</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {statusData.map((status) => (
                            <TableRow key={status.source_country}>
                                <TableCell className="font-medium">
                                    {status.source_country}
                                </TableCell>
                                <TableCell>
                                    {getStatusIndicator(status.minutes_ago)}
                                </TableCell>
                                <TableCell>
                                    {format(new Date(status.last_request_time), 'yyyy-MM-dd HH:mm:ss')}
                                </TableCell>
                                <TableCell className="text-muted-foreground">
                                    {getTimeAgo(status.last_request_time)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <div className="mt-4 text-sm text-muted-foreground">
                    <p>Status indicators:</p>
                    <ul className="list-disc list-inside mt-2">
                        <li>Green: Request within the last 2 hours</li>
                        <li>Red: No requests in the last 2 hours</li>
                    </ul>
                </div>
            </CardContent>
        </Card>
    );
};

export default CrawlerStatus;
