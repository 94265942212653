import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';

const MetricsDashboard = () => {
    const [metrics, setMetrics] = useState({
        successRate: [],
        responseTimes: [],
        loading: true,
        error: null
    });

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch('/api/dashboard/news/metrics');
                const data = await response.json();

                if (!data.metrics || !Array.isArray(data.metrics)) {
                    throw new Error('Invalid metrics data format');
                }

                const processedData = processMetricsData(data.metrics);
                setMetrics({
                    ...processedData,
                    loading: false
                });
            } catch (error) {
                console.error('Error fetching metrics:', error);
                setMetrics(prev => ({
                    ...prev,
                    loading: false,
                    error: 'Failed to load metrics'
                }));
            }
        };

        fetchMetrics();
        const interval = setInterval(fetchMetrics, 300000); // 5 minutes
        return () => clearInterval(interval);
    }, []);

    const processMetricsData = (rawData) => {
        const hourlyData = rawData.reduce((acc, item) => {
            try {
                const date = new Date(item.created_at.replace(' ', 'T'));
                const hour = date.toISOString().slice(0, 13);

                if (!acc[hour]) {
                    acc[hour] = {
                        total: 0,
                        successful: 0,
                        responseTimes: [],
                        timeString: item.created_at
                    };
                }

                acc[hour].total++;
                if (item.status_code >= 200 && item.status_code < 300) {
                    acc[hour].successful++;
                }
                if (item.response_time_ms) {
                    acc[hour].responseTimes.push(item.response_time_ms);
                }
            } catch (error) {
                console.error('Error processing data item:', error, item);
            }
            return acc;
        }, {});

        const chartData = Object.entries(hourlyData).map(([hour, stats]) => ({
            timeString: stats.timeString,
            successRate: (stats.successful / stats.total * 100) || 0,
            avgResponseTime: stats.responseTimes.reduce((a, b) => a + b, 0) / stats.responseTimes.length || 0
        }));

        return {
            successRate: chartData.sort((a, b) => new Date(a.timeString) - new Date(b.timeString)),
            loading: false
        };
    };

    if (metrics.loading) {
        return <div className="animate-pulse">Loading metrics...</div>;
    }

    if (metrics.error) {
        return (
            <div className="p-4 bg-destructive/10 text-destructive rounded-md">
                {metrics.error}
            </div>
        );
    }

    return (
        <div className="grid gap-4 md:grid-cols-2">
            {/* Success Rate Chart */}
            <Card className="w-full">
                <CardHeader>
                    <CardTitle>Success Rate Trend</CardTitle>
                </CardHeader>
                <CardContent className="h-[400px]">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={metrics.successRate}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="timeString"
                                tickFormatter={(timeStr) => {
                                    try {
                                        const date = new Date(timeStr.replace(' ', 'T'));
                                        return format(date, 'HH:mm');
                                    } catch (error) {
                                        console.error('Error formatting time:', error, timeStr);
                                        return '';
                                    }
                                }}
                            />
                            <YAxis
                                name="Success Rate"
                                unit="%"
                            />
                            <Tooltip
                                formatter={(value) => `${value.toFixed(2)}%`}
                                labelFormatter={(timeStr) => {
                                    const date = new Date(timeStr.replace(' ', 'T'));
                                    return format(date, 'yyyy-MM-dd HH:mm');
                                }}
                            />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="successRate"
                                name="Success Rate"
                                stroke="#10b981"
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </CardContent>
            </Card>

            {/* Response Time Chart */}
            <Card className="w-full">
                <CardHeader>
                    <CardTitle>Response Time Trend</CardTitle>
                </CardHeader>
                <CardContent className="h-[400px]">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={metrics.successRate}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="timeString"
                                tickFormatter={(timeStr) => {
                                    try {
                                        const date = new Date(timeStr.replace(' ', 'T'));
                                        return format(date, 'HH:mm');
                                    } catch (error) {
                                        console.error('Error formatting time:', error, timeStr);
                                        return '';
                                    }
                                }}
                            />
                            <YAxis
                                name="Response Time"
                                unit=" ms"
                            />
                            <Tooltip
                                formatter={(value) => `${value.toFixed(0)} ms`}
                                labelFormatter={(timeStr) => {
                                    const date = new Date(timeStr.replace(' ', 'T'));
                                    return format(date, 'yyyy-MM-dd HH:mm');
                                }}
                            />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="avgResponseTime"
                                name="Avg Response Time"
                                stroke="#3b82f6"
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </CardContent>
            </Card>
        </div>
    );
};

export default MetricsDashboard;
